<style scoped>
.svgContent {
  width: 100%;
  text-align: center
}
</style>

<template>
  <div id="mapTest">
    <div v-html="svgHtml" id="svgLine_download" class="svgContent"></div>
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'

import { getCitys } from '@/api/product/asset'
import { getCitySvg } from '@/api/rim/asset'

export default {
  data () {
    return {
      svgHtml: '',
      publisherId: this.$store.getters.token.userInfo.publisherId,

      assetColors: new Map(),
      currentMapObj: null,
      pathNodeList: [],
      gNodeList: [],

      mapCenterPoint: '267,5307',
      stationCoordinateArray: [] // 站点坐标体系
    }
  },
  mounted () {
    this.initMap()
    // 设置中间区域地图高度
    this.$nextTick(() => {
      document.getElementById('svgLine_download').style.height = document.body.clientHeight - 5 + 'px'
    })
  },
  methods: {
    initMap () {
      this.stationCoordinateArray = []
      this.$emit('map-finished', false)

      getCitys({ publisherId: this.publisherId }).then(citys => {
        const data = {
          cityCode: citys[0].cityCode,
          categoryId: null,
          publisherId: this.publisherId
        }

        getCitySvg(data).then(res => {
          this.svgHtml = res
          // 设置SVG地图的样式和缩放
          this.$nextTick(() => {
            // 设置svg呈现在画板上的尺寸
            document.getElementById('drawing').setAttribute('width', '100%')
            document.getElementById('drawing').setAttribute('height', '100%')

            this.currentMapObj = svgPanZoom('#drawing', {
              panEnabled: true,
              controlIconsEnabled: false,
              zoomEnabled: true,
              dblClickZoomEnabled: true,
              minZoom: 1,
              maxZoom: 5,
              center: true
            }).zoom(1.5)

            this.pathNodeList = document.getElementById('svgLine_download').getElementsByTagName('path')
            this.gNodeList = document.getElementById('svgLine_download').getElementsByTagName('g')

            // 存储线路颜色
            this.saveAssetColor()
            this.$emit('map-finished', true)
          })
        })
      })
    },
    saveAssetColor () {
      this.assetColors = new Map()
      // 线条
      let gEl
      for (let i = 0; i < this.gNodeList.length; i++) {
        gEl = this.gNodeList[i]
        if (gEl.hasAttribute('data-assetid')) { // 当前节点包含线路ID
          if (!this.assetColors.get(gEl.getAttribute('data-assetid'))) {
            this.assetColors.set(gEl.getAttribute('data-assetid'), gEl.children[0].getAttribute('stroke'))
          }
        }
      }
    },
    hideOtherAsset (assetIds) {
      // 线路自动定位在最后一条上
      if (assetIds.length > 0) {
        this.handleMapGetCenterByLine(assetIds[assetIds.length - 1].toString())
      }

      // 线条
      let gEl
      let lineAssetId
      const assetHideColor = '#E6E6E6'
      for (let i = 0; i < this.gNodeList.length; i++) {
        gEl = this.gNodeList[i]
        if (gEl.hasAttribute('data-assetid')) {
          // 隐藏线路，将线路颜色变为灰色
          lineAssetId = parseInt(gEl.getAttribute('data-assetid'))
          if (assetIds.indexOf(lineAssetId) > -1) {
            // 还原线路颜色
            gEl.children[0].setAttribute('stroke', this.assetColors.get(gEl.getAttribute('data-assetid')))
          } else {
            gEl.children[0].setAttribute('stroke', assetHideColor)
          }
        }
      }

      // 站点
      let pEl
      let stationAssetId
      let flag
      for (let i = 0; i < this.pathNodeList.length; i++) {
        pEl = this.pathNodeList[i]
        if (pEl.hasAttribute('data-assetid')) {
          stationAssetId = pEl.getAttribute('data-assetid')
          flag = false
          if (stationAssetId.split(',').length > 1) {
            // 如果是换乘站, 验证是否包含在显示线路目录中
            stationAssetId.split(',').forEach(lineId => {
              if (assetIds.indexOf(parseInt(lineId)) > -1) {
                flag = true
              }
            })
          }

          if (flag || assetIds.indexOf(parseInt(stationAssetId)) > -1) {
            // 还原站点颜色
            this.fillStationColor(pEl, true)
          } else {
            this.fillStationColor(pEl, false)
          }
        }
      }
    },
    fillStationColor (pEl, isShow) {
      let stationFill = '#E6E6E6'
      let stationStroke = '#E6E6E6'
      let stationText = '#E6E6E6'

      if (isShow) {
        stationStroke = this.assetColors.get(pEl.getAttribute('data-assetid'))
        stationFill = '#ffffff'
        stationText = '#000'
      }

      if (pEl.getAttribute('name') === 'standardText') { // 常规站点
        pEl.nextSibling.nextElementSibling.children[0].setAttribute('fill', stationText)
      } else if (pEl.getAttribute('name') === 'p_r_station') { // 换乘站
        pEl.setAttribute('display', isShow ? '' : 'none')
      } else { // 站点圆圈
        pEl.setAttribute('stroke', stationStroke)
        pEl.setAttribute('fill', stationFill)
      }
    },
    handleMapGetCenterByLine (lineId) {
      // 获取线路的全部站点
      const stationList = []
      let pEl

      for (let i = 0; i < this.pathNodeList.length; i++) {
        pEl = this.pathNodeList[i]
        if (pEl.hasAttribute('data-stationid') && (pEl.getAttribute('name') === 'p_station' || pEl.getAttribute('name') === 'p_r_station')) {
          if (pEl.hasAttribute('data-assetid') && pEl.getAttribute('data-assetid').split(',').indexOf(lineId) > -1) {
            const centerPointStr = pEl.parentNode.getAttribute('transform').substr(10).split(')')[0].split(' ')
            stationList.push({
              id: pEl.getAttribute('data-stationid'),
              x: parseInt(centerPointStr[0]),
              y: parseInt(centerPointStr[1])
            })
          }
        }
      }

      // 计算出实际跳转坐标
      // const stationSortList = stationList.sort((a, b) => { return b.id > a.id })
      // const centerPoint = stationSortList[parseInt(stationList.length / 2)]
      const centerPoint = stationList[parseInt(stationList.length / 2)]
      if (centerPoint) {
        const svgSizes = this.currentMapObj.getSizes()
        const newPoint = {
          x: -(centerPoint.x * svgSizes.realZoom) + (svgSizes.width / 2),
          y: -(centerPoint.y * svgSizes.realZoom) + (svgSizes.height / 2)
        }

        this.handleMapAnimation(newPoint)
      }
    },
    handleMapAnimation (targetPoint) {
      const _self = this
      const currentPan = this.currentMapObj.getPan()

      var animationTime = 300 // ms
      var animationStepTime = 30 // one frame per 30 ms
      var animationSteps = animationTime / animationStepTime
      var animationStep = 0
      var intervalID = null
      var stepX = (currentPan.x - targetPoint.x) / animationSteps
      var stepY = (currentPan.y - targetPoint.y) / animationSteps

      intervalID = setInterval(function () {
        if (animationStep++ < animationSteps) {
          _self.currentMapObj.pan({ x: currentPan.x - stepX * animationStep, y: currentPan.y - stepY * animationStep })
        } else {
          // 取消无限循环, 获取页面站点坐标系
          clearInterval(intervalID)
        }
      }, animationStepTime)
    }
  }
}
</script>
