<template>
  <div class="heat-content">
    <!-- 显示下载条件 -->
    <Drawer :closable="false" v-model="showSearchCondition" :mask="false">
      <p class="remark m-b-20">
        tips: 当前地图内看到的地图样式， 即为下载样式
      </p>

      <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
        <Checkbox
            :indeterminate="indeterminate"
            :value="checkAll"
            @click.prevent.native="handleCheckAll">全选</Checkbox>
      </div>
      <CheckboxGroup v-model="checkAllGroup" size="large" @on-change="checkAllGroupChange">
        <Checkbox v-for="(line,index) in assetArray" :key="index" :label="line.id">{{ line.name }}</Checkbox>
      </CheckboxGroup>

      <Row class="p-t-10 p-b-10">
        <i-col span="24">
          <Button type="success" size="large" long @click="handleDownloadMap">开始下载</Button>
        </i-col>
      </Row>
    </Drawer>

    <lineMap ref="lineMapComponent" @map-finished="handleMapLoadFinished"/>

    <!-- 隐藏图层，用于下载站点地图时，克隆SVG代码 -->
    <div id="download-svg-line" class="download-svg-css"></div>
    <Spin v-if="spinShow" fix>
      <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
      <div>数据加载中...</div>
    </Spin>
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import lineMap from './components/LineMap'

import { HTMLEncode } from '@/utils'
import { downloadFilePostRequest } from '@/utils/download'

import { getCitys } from '@/api/product/asset'
import { getAllByCityCode } from '@/api/rim/asset'

export default {
  components: {
    lineMap
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      showSearchCondition: true,
      spinShow: false,
      mapFinished: false,

      assetArray: [],
      indeterminate: true,
      checkAll: true,
      checkAllGroup: []
    }
  },
  created () {
    this.initBaseData()
  },
  methods: {
    initBaseData () {
      getCitys({ publisherId: this.publisherId }).then(citys => {
        const query = { cityCode: citys[0].cityCode }
        getAllByCityCode(query).then(res => {
          this.assetArray = res.filter(x => x.mediatypeId === 1)
          this.checkAllGroup = this.assetArray.map(x => x.id)
        })
      })
    },
    handleMapLoadFinished (val) {
      this.mapFinished = val
    },
    handleCheckAll () {
      if (this.indeterminate) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.indeterminate = false

      if (this.checkAll) {
        this.checkAllGroup = this.assetArray.map(x => x.id)
      } else {
        this.checkAllGroup = []
      }

      this.$refs.lineMapComponent.hideOtherAsset(this.checkAllGroup)
    },
    checkAllGroupChange (data) {
      if (data.length === this.assetArray.length) {
        this.indeterminate = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.indeterminate = true
        this.checkAll = false
      } else {
        this.indeterminate = false
        this.checkAll = false
      }

      this.$refs.lineMapComponent.hideOtherAsset(this.checkAllGroup)
    },
    handleDownloadMap () {
      this.spinShow = true
      const dowloadFileName = this.$store.getters.token.userInfo.publisherName + '线路图.png'
      const downloadElement = document.getElementById('download-svg-line')
      downloadElement.innerHTML = document.getElementById('svgLine_download').innerHTML

      this.$nextTick(() => {
        const currMap = svgPanZoom('#download-svg-line svg')
        currMap.zoomAtPointBy(4, { x: -200, y: -200 })

        const that = this
        setTimeout(function () {
          that.spinShow = false
          const postData = {
            svg: HTMLEncode(downloadElement.innerHTML)
          }
          downloadFilePostRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-draw/svg/downloadsvgpng', postData, dowloadFileName)
          downloadElement.innerHTML = ''
        }, 1000)
      })
    }
  }
}
</script>

<style scoped>
.heat-content{
  height: 100vh;
  width: 100vw;
  background-color: white;
}
.download-svg-css{
  width: 1280px;
  height: 768px;
  z-index: -1;
  position: absolute;
  top: 50px;
}
</style>
