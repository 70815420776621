import { getStorage, setStorage, removeStorage } from './storage'
import { ParseDate } from './dateFormat'
export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

export function formatTime (time, option) {
  // time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    // return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'

    return d.getFullYear() === new Date().getFullYear() ? (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0') : d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0')
  }
}

// 格式化URL查询参数
export function getQueryObject (url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getByteLen (val) {
  let len = 0
  for (let i = 0; i < val.length; i++) {
    if (val[i].match('/[^\x00-\xff]/ig') != null) {
      len += 1
    } else { len += 0.5 }
  }
  return Math.floor(len)
}

export function cleanArray (actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

export function param (json) {
  if (!json) return ''
  return cleanArray(Object.keys(json).map(key => {
    if (json[key] === undefined) return ''
    return encodeURIComponent(key) + '=' +
      encodeURIComponent(json[key])
  })).join('&')
}

export function param2Obj (url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

export function HTMLEncode (html) {
  // 1.首先动态创建一个容器标签元素，如DIV
  var temp = document.createElement('div');
  // 2.然后将要转换的字符串设置为这个元素的innerText或者textContent
  (temp.textContent != null) ? (temp.textContent = html) : (temp.innerText = html)
  // 3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
  var output = temp.innerHTML
  temp = null
  return output
}

export function HTMLDecode (text) {
  // 1.首先动态创建一个容器标签元素，如DIV
  var temp = document.createElement('div')
  // 2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
  temp.innerHTML = text
  // 3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
  var output = temp.innerText || temp.textContent
  temp = null
  return output
}

export function objectMerge (target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  for (const property in source) {
    if (Object.prototype.hasOwnProperty.call(source, property)) {
      const sourceProperty = source[property]
      if (typeof sourceProperty === 'object') {
        target[property] = objectMerge(target[property], sourceProperty)
        continue
      }
      target[property] = sourceProperty
    }
  }
  return target
}

export function scrollTo (element, to, duration) {
  if (duration <= 0) return
  const difference = to - element.scrollTop
  const perTick = difference / duration * 10
  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick
    if (element.scrollTop === to) return
    scrollTo(element, to, duration - 10)
  }, 10)
}

export function toggleClass (element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

export const pickerOptions = [
  {
    text: '今天',
    onClick (picker) {
      const end = new Date()
      const start = new Date(new Date().toDateString())
      end.setTime(start.getTime())
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一周',
    onClick (picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一个月',
    onClick (picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近三个月',
    onClick (picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      picker.$emit('pick', [start, end])
    }
  }]

export function getTime (type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

export function debounce (func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export function deepClone (source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  for (const keys in source) {
    if (Object.prototype.hasOwnProperty.call(source, keys)) {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = source[keys].constructor === Array ? [] : {}
        targetObj[keys] = deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    }
  }
  return targetObj
}

// 分页查询缓存到localStorage
export function setPageSearch (query) {
  removeStorage('TabelSearch')
  setStorage('TabelSearch', query, 3600)
}

// 获取分页查询缓存
export function getPageSearch (obj) {
  var token = obj.$route.path
  if (getStorage('Tabel' + token + '')) {
    return getStorage('Tabel' + token + '')
  } else {
    return setStorage('Tabel' + token + '', obj.query, 3600)
  }
}

/**
 * 将一个数字转换为大写的货币
 * @param {Number} money
 */
export function parseUpperMoney (money) {
  money = money || 0
  const fraction = ['角', '分']
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']]
  const head = money < 0 ? '负' : ''
  let number = Math.abs(money)

  let fractionString = ''

  for (let i = 0; i < fraction.length; i++) {
    fractionString += (digit[Math.floor(money * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
  }

  fractionString = fractionString || '整'
  number = Math.floor(number)

  for (let i = 0; i < unit[0].length && number > 0; i++) {
    var intString = ''
    for (var j = 0; j < unit[1].length && number > 0; j++) {
      intString = digit[number % 10] + unit[1][j] + intString
      number = Math.floor(number / 10)
    }
    fractionString = intString.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + fractionString
  }
  return head + fractionString.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整')
}

/**
 * 返回一组日期中最晚的日期，以 yyyy-mm-dd 格式
 * @param {Array} 以 yyyy-mm-dd 格式化过的日期字符串数组
 */
export function getLaterDate (dateArray) {
  dateArray = dateArray.map((item) => {
    if (typeof item !== 'string') {
      return ParseDate(item)
    } else {
      return item
    }
  })
  const late = dateArray[0]
  let lateDate = Number(late.replace(/-/g, ''))
  for (const date of dateArray) {
    const dateNumber = Number(date.replace(/-/g, ''))
    if (dateNumber > lateDate) {
      lateDate = dateNumber
    }
  }
  return formateDateNumber(lateDate)
}
function formateDateNumber (number) {
  const dateStr = number.toString()
  let format = 'xxxx-xx-xx'
  for (let index = 0; index < dateStr.length; index++) {
    format = format.replace('x', dateStr[index])
  }
  return format
}

/**
 * @returns {Boolean}
 * @param {String} left,一个‘YYYY-MM-DD’格式的日期字符串
 * @param {String} right，一个‘YYYY-MM-DD’格式的日期字符串
 */
export function earlierThan (left, right) {
  const leftNumber = left.replace(/-/g, '')
  const rightNumber = right.replace(/-/g, '')
  return Number(leftNumber) <= Number(rightNumber)
}

/**
 * 返回一组日期中最早的日期，以 yyyy-mm-dd 格式
 * @param {Array} 以 yyyy-mm-dd 格式化过的日期字符串数组
 */
export function getEarlierDate (dateArray) {
  if (dateArray.length > 0) {
    let early = dateArray[0]
    for (const date of dateArray) {
      if (early > date) {
        early = date
      }
    }
    return early
  }
}
// 阿拉伯数字转中文数字
export function NoToChinese (num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    alert('Number is wrong!')
    return 'Number is wrong!'
  }
  var AA = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  var BB = ['', '十', '百', '千', '万', '亿', '点', '']
  var a = ('' + num).replace(/(^0*)/g, '').split('.')
  var k = 0
  var re = ''
  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re
        break
      case 4:
        if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0])) { re = BB[4] + re }
        break
      case 8:
        re = BB[5] + re
        BB[7] = BB[5]
        k = 0
        break
    }
    if (k % 4 === 2 && a[0].charAt(i + 2) !== 0 && a[0].charAt(i + 1) === 0) re = AA[0] + re
    if (a[0].charAt(i) !== 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re
    k++
  }
  if (a.length > 1) { // 加上小数部分(如果有小数部分)
    re += BB[6]
    for (var j = 0; j < a[1].length; j++) re += AA[a[1].charAt(j)]
  }
  return re
};
// num传入的数字，n需要的字符长度
export function PrefixInteger (num, n) {
  return (Array(n).join(0) + num).slice(-n)
};
